import { checkoutEndpoints } from '@redux/features/checkout/api'
import { TRIP_MODE } from '@redux/features/search/constants/search.constants'
import { searchEndpoints } from '@redux/features/search/search.api'
import type { ISearchState } from '@redux/features/search/types/searchState'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

const initialState: ISearchState = {
  hasTravelerPickerErrors: false,
  showAffiliatePlaceholder: false,
  tripMode: TRIP_MODE.ONE_WAY,
  withLayover: false,
}

const searchSlice = createSlice({
  extraReducers: builder => {
    builder
      .addMatcher(searchEndpoints.getSearchHistory.matchFulfilled, (state, { payload }) => {
        state.tripMode = payload?.[0]?.round_trip ? TRIP_MODE.ROUND_TRIP : TRIP_MODE.ONE_WAY
      })
      .addMatcher(checkoutEndpoints.postSearch.matchPending, state => {
        state.showAffiliatePlaceholder = false
      })
  },
  initialState,
  name: 'search',
  reducers: {
    setDepartureDate: (state, action: PayloadAction<string>) => {
      state.departureDate = action.payload
    },
    setHasTravelerPickerErrors: (state, action: PayloadAction<boolean>) => {
      state.hasTravelerPickerErrors = action.payload
    },
    setShowAffiliatePlaceholder: (state, action: PayloadAction<boolean>) => {
      state.showAffiliatePlaceholder = action.payload
    },
    setWithLayover: (state, action: PayloadAction<boolean>) => {
      state.withLayover = action.payload
    },
    switchTripMode: (state, action: PayloadAction<TRIP_MODE | undefined>) => {
      state.tripMode =
        action.payload ?? (state.tripMode === TRIP_MODE.ROUND_TRIP ? TRIP_MODE.ONE_WAY : TRIP_MODE.ROUND_TRIP)
    },
  },
})

export const {
  setDepartureDate,
  setHasTravelerPickerErrors,
  setShowAffiliatePlaceholder,
  setWithLayover,
  switchTripMode,
} = searchSlice.actions

export const { name: searchSliceName } = searchSlice

export default searchSlice.reducer
