import { api } from '@redux/api/api'
import { TagTypes } from '@redux/api/tagTypes'
import type { IUserInfo } from '@redux/features/user/types/userInfo'

import authStorage from '@storageServices/storageEntities/auth'
import userInfoStorage from '@storageServices/storageEntities/userInfoStorage'
import cleanUserDataStorage from '@utils/browserStorages/cleanUserDataStorage'

import userUrls from '../constants/urls'

const userApi = api.injectEndpoints({
  endpoints: build => ({
    getCurrentUserInfo: build.query<IUserInfo | undefined, void>({
      providesTags: [TagTypes.POST_LOGIN, TagTypes.POST_LOGOUT],
      query: () => ({
        method: 'GET',
        url: userUrls.currentUser,
      }),
      transformErrorResponse: error => {
        cleanUserDataStorage()
        return error
      },
      transformResponse: (response: IUserInfo) => {
        if (response.uid === 0) {
          authStorage.clear()
          return
        }

        response.mail && userInfoStorage.addValues({ email: response.mail })
        response.api_key &&
          authStorage.setValue({
            roles: response.roles,
            userId: response.uid,
            'X-Auth-Token': response.api_key,
          })
        return response
      },
    }),
  }),
})

export const { endpoints: userEndpoints, useGetCurrentUserInfoQuery } = userApi
