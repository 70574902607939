import { searchEndpoints } from '@redux/features/search/search.api'
import type { RootState } from '@redux/store'
import { createAsyncThunk } from '@reduxjs/toolkit'

export default createAsyncThunk<boolean, boolean, { state: RootState }>(
  'search/initAffiliateProgram',
  async (disabledByCookie, { dispatch, getState }) => {
    const response =
      searchEndpoints.getAffiliateProgram.select()(getState()).data ||
      (await dispatch(searchEndpoints.getAffiliateProgram.initiate()).unwrap())

    return !response.enabled ? response.enabled : !disabledByCookie
  }
)
