import { myTripsEndpoints } from '@redux/features/myTrips/api'
import { userEndpoints } from '@redux/features/user/api'
import type { RootState } from '@redux/store'
import { createAsyncThunk } from '@reduxjs/toolkit'

import authStorage from '@storageServices/storageEntities/auth'

export default createAsyncThunk<void, void, { state: RootState }>(
  'authorization/checkTempAuthData',
  async (_, { dispatch }) => {
    const tempData = authStorage.getValueByKey('temp')
    tempData?.['X-Auth-Token'] &&
      authStorage.setValue({ temp: { 'X-Auth-Token': undefined }, 'X-Auth-Token': tempData['X-Auth-Token'] })
    if (tempData?.userId) {
      const userInfo = await dispatch(userEndpoints.getCurrentUserInfo.initiate()).unwrap()

      userInfo?.uid && void dispatch(myTripsEndpoints.getUpcomingStoreOrders.initiate(userInfo.uid))
    }
  }
)
