import isEqual from 'lodash/isEqual'

import { paymentEndpoints } from '@redux/features/payment/api'
import type { PaymentState } from '@redux/features/payment/types/paymentState'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice, isAnyOf } from '@reduxjs/toolkit'

import { PaymentStatuses } from '@constants/payment/putPaymentResponse'

import { checkoutEndpoints } from '../checkout/api'

const initialState: PaymentState = {
  isPaymentInProgress: false,
}

const paymentSlice = createSlice({
  extraReducers: builder => {
    builder
      .addMatcher(
        isAnyOf(
          paymentEndpoints.getPayment.matchFulfilled,
          paymentEndpoints.patchPayment.matchFulfilled,
          paymentEndpoints.putPayment.matchFulfilled
        ),
        (state, { meta, payload }) => {
          if (meta.arg.endpointName !== paymentEndpoints.getPayment.name || !isEqual(payload, state.paymentData)) {
            state.paymentData = payload
          }
        }
      )
      .addMatcher(isAnyOf(checkoutEndpoints.postBooking.matchFulfilled), state => {
        state.paymentData = undefined
      })
      .addMatcher(
        isAnyOf(
          paymentEndpoints.getPayment.matchFulfilled,
          paymentEndpoints.putPayment.matchFulfilled,
          paymentEndpoints.patchPayment.matchFulfilled
        ),
        (state, { payload }) => {
          if (payload.paymentStatus === PaymentStatuses.FAILED) {
            state.isPaymentInProgress = false
          }
        }
      )
  },
  initialState,
  name: 'payment',
  reducers: {
    clearPaymentState: () => initialState,
    setPaymentProgressState: (state, action: PayloadAction<boolean>) => {
      state.isPaymentInProgress = action.payload
    },
  },
})

export const { clearPaymentState, setPaymentProgressState } = paymentSlice.actions

export default paymentSlice.reducer
