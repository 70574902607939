import type { CartLegsType, ICartState } from '@redux/features/cart/types/cartState'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

const initialState: ICartState = {
  legs: [],
}

const cartSlice = createSlice({
  initialState,
  name: 'cart',
  reducers: {
    cleanCart: state => {
      state.legs = initialState.legs
    },
    replaceCartLegs: (state, action: PayloadAction<CartLegsType[]>) => {
      state.legs = action.payload
    },
    setCartLeg: (state, action: PayloadAction<CartLegsType & { legNumber: number }>) => {
      const { legNumber, ...cartLeg } = action.payload
      state.legs[legNumber - 1] = cartLeg
    },
  },
})

export const { cleanCart, replaceCartLegs, setCartLeg } = cartSlice.actions
export const { name: cartSliceName } = cartSlice

export default cartSlice.reducer
