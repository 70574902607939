import Popover from 'antd/es/popover'
import type { TooltipPlacement } from 'antd/es/tooltip'
import dynamic from 'next/dynamic'
import type { FC } from 'react'
import { useEffect, useRef } from 'react'

import { selectUserCurrency } from '@redux/features/user/user.selectors'
import { useAppSelector } from '@redux/hooks'
import { selectIsPageInteraction } from '@reduxCommonApp/commonApp.selectors'

import { mainHeaderId } from '@constants/elementsIds/header'
import useToggle from '@hooks/useToggle/useToggle'

import MenuContainer from './components/MenuContainer'

const RegionalMenuDropdown = dynamic(() => import('./components/RegionalMenuDropdown'))

export type RegionalMenuVariantType = 'classic' | 'default' | 'modern'

type Props = {
  placement?: TooltipPlacement
  variant?: RegionalMenuVariantType
}

export const RegionalMenu: FC<Props> = ({ placement = 'bottomRight', variant = 'default' }) => {
  const currentCurrency = useAppSelector(selectUserCurrency)
  const isPageInteraction = useAppSelector(selectIsPageInteraction)
  const { on: isOpen, setValue: onOpenChange, toggleOff: close } = useToggle()

  const prevCurrencyRef = useRef<typeof currentCurrency>(currentCurrency)

  useEffect(() => {
    if (prevCurrencyRef.current === currentCurrency) return
    close()
    prevCurrencyRef.current = currentCurrency
  }, [close, currentCurrency])

  return (
    <Popover
      arrow={false}
      content={isPageInteraction ? <RegionalMenuDropdown /> : undefined}
      destroyTooltipOnHide={false}
      getPopupContainer={node => document.getElementById(mainHeaderId) || node}
      onOpenChange={onOpenChange}
      open={isOpen}
      overlayInnerStyle={{ background: 'none', boxShadow: 'none', padding: 0 }}
      placement={placement}
      trigger="click"
    >
      <MenuContainer isActive={isOpen} variant={variant} />
    </Popover>
  )
}
