import { useLayoutEffect } from 'react'

import { setDeviceType } from '@redux/features/commonApp/commonApp.slice'
import { useAppDispatch } from '@redux/hooks'

import { DeviceType } from '@Types/common/device'
import useMediaQuery from '@hooks/mediaQueries/useMediaQuery'
import { mediaQueries } from '@themes/mediaQueries'

export default () => {
  const dispatch = useAppDispatch()
  const mediaQueryIsMobile = useMediaQuery(mediaQueries.mobile)
  const mediaQueryIsTablet = useMediaQuery(mediaQueries.tablet)

  useLayoutEffect(() => {
    const handleChange = () =>
      dispatch(
        setDeviceType(
          mediaQueryIsMobile ? DeviceType.MOBILE : mediaQueryIsTablet ? DeviceType.TABLET : DeviceType.DESKTOP
        )
      )

    const timeoutId = setTimeout(handleChange) //because at boundary width mediaQueryIsMobile, mediaQueryIsTablet have same value

    return () => clearTimeout(timeoutId)
  }, [mediaQueryIsTablet, mediaQueryIsMobile])
}
