import { api } from '@redux/api/api'
import commonUrls from '@redux/features/commonApp/constants/urls'
import type { CurrenciesList, LanguagesList, TranslationValues } from '@reduxCommonApp/types/commonAppState'
import type { AllExperimentsInfo, ExperimentConfig } from '@reduxCommonApp/types/experimentInfo'
import type { ISiteInfo } from '@reduxCommonApp/types/siteInfo'

import type { ConfigurationType } from '@Types/common/configurationBlocks'
import { urlVersionPrefix, urlVersionPrefixV2 } from '@constants/api/api'
import type { ConfigurationFrontendTypes } from '@constants/configuration/configuration'

const commonAppApi = api.injectEndpoints({
  endpoints: build => ({
    getConfiguration: build.query<ConfigurationType, ConfigurationFrontendTypes>({
      keepUnusedDataFor: 600,
      query: (type: ConfigurationFrontendTypes) => ({ url: urlVersionPrefix + `frontend-ui/${type}` }),
    }),
    getCurrencies: build.query<CurrenciesList, boolean | void>({
      keepUnusedDataFor: 0,
      query: visible => ({
        params: { visible },
        url: urlVersionPrefixV2 + `currencies`,
      }),
    }),
    getCurrencySwitch: build.query<void, string>({
      query: currency => ({ url: commonUrls.switchCurrency(currency) }),
    }),
    getExperimentInfo: build.query<AllExperimentsInfo, void>({
      query: () => ({ url: commonUrls.getExperimentInfo }),
    }),
    getFile: build.query<FileType, number>({
      query: fileId => ({ url: urlVersionPrefix + `entity/file/${fileId}` }),
    }),
    getLanguages: build.query<LanguagesList, void>({
      query: () => ({ url: urlVersionPrefix + 'languages' }),
    }),
    getSiteInfo: build.query<ISiteInfo, boolean>({
      // boolean argument true if server side, need for correct user_country_code
      query: () => ({
        url: commonUrls.getSiteInfo,
      }),
    }),
    getTranslations: build.query<TranslationValues, { lang?: string }>({
      query: ({ lang }) => ({ url: lang || '' + urlVersionPrefix + 'translation' }),
    }),
    postExperimentAssignment: build.query<ExperimentConfig | undefined, boolean>({
      // boolean argument needed to ignore the request
      query: () => ({
        body: {},
        method: 'POST',
        url: `${commonUrls.experimentAssignment}${typeof window !== 'undefined' ? document.location.search : ''}`,
      }),
      transformResponse: (response: [] | ExperimentConfig) => {
        return Array.isArray(response) ? undefined : response
      },
    }),
  }),
})

export const {
  endpoints: commonAppEndpoints,
  useGetConfigurationQuery,
  useGetCurrenciesQuery,
  useGetExperimentInfoQuery,
  useGetLanguagesQuery,
  useGetSiteInfoQuery,
  useGetTranslationsQuery,
  useLazyPostExperimentAssignmentQuery,
  usePostExperimentAssignmentQuery,
} = commonAppApi

export const { getConfiguration, getCurrencies, getCurrencySwitch, getLanguages, getTranslations } = commonAppEndpoints
