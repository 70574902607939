import { useRouter } from 'next/router'

import { getRoutes, paths } from '@constants/routes/routes'
import useVersionPage from '@hooks/useVersionPage'

const useIsMainPage = () => {
  const router = useRouter()
  const pathname = router.asPath
  const versionPage = useVersionPage()

  return pathname === getRoutes.rootPath(versionPage) || pathname.includes(paths.routePage)
}

export default useIsMainPage
