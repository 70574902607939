import { createContext } from 'react'
import type { WelcomeLayoutPageServerSidePropsType } from 'shared/redux/features/welcomPage/getWelcomeLayoutsPageServerSideProps'

import { DeviceType } from '@Types/common/device'
import { ThemeNames } from '@themes/themes'
import type { DefaultServerSidePropsType } from '@utils/serverSideProps/getDefaultServerSideProps'

export default createContext<DefaultServerSidePropsType | WelcomeLayoutPageServerSidePropsType>({
  deviceType: DeviceType.DESKTOP,
  themeKey: ThemeNames.LIGHT,
})
