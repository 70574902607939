import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { logout } from '@redux/features/authorization/thunks'
import { useAppDispatch } from '@redux/hooks'

import { getRoutes, paths } from '@constants/routes/routes'
import useIsLoggedIn from '@hooks/auth/useIsLoggedIn'
import useVersionPage from '@hooks/useVersionPage'

export const useLogOut = () => {
  const isLoggedIn = useIsLoggedIn()
  const dispatch = useAppDispatch()
  const router = useRouter()
  const verPage = useVersionPage()

  const logOutHandler = useCallback(() => {
    if (!isLoggedIn) return
    void dispatch(logout())

    if (router.pathname.includes(paths.order)) {
      void router.push(getRoutes.rootPath(verPage))
    }
  }, [dispatch, isLoggedIn, verPage, router])

  return { isLoggedIn, logOutHandler }
}
