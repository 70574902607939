import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

const initialState: AnalyticsState = {
  isGTMLoaded: false,
}

const analyticsSlice = createSlice({
  initialState,
  name: 'analytics',

  reducers: {
    setIsGTMLoaded: (state, action: PayloadAction<boolean>) => {
      state.isGTMLoaded = action.payload
    },
  },
})

export const { setIsGTMLoaded } = analyticsSlice.actions

export default analyticsSlice.reducer
