import { LocalesList } from '@constants/locales/localesList'

export const flagByLanguageId: Partial<Record<LocalesList, string>> = {
  [LocalesList.AR]: 'sa', // Saudi Arabia
  [LocalesList.CN]: 'cn', // China
  [LocalesList.EL]: 'gr', // Greece
  [LocalesList.EN]: 'us', // United States
  [LocalesList.JP]: 'jp', // Japan
  [LocalesList.KO]: 'kr', // South Korea
  [LocalesList.NB]: 'no', // Norway
  [LocalesList.SV]: 'se', // Sweden
  [LocalesList.TW]: 'tw', // Taiwan
}

export const getFlagCodeByLanguageId = (languageId: LocalesList) => flagByLanguageId[languageId] || languageId
