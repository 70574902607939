import type {
  AiConversationMessage,
  AiDataState,
  AiSearchOptionsData,
  ConversationResponse,
} from '@redux/features/ai/types/ai'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

const initialState: AiDataState = {
  config: null,
  conversationId: null,
  linkedOptionIds: [],
  loading: false,
  messages: [],
  options: [],
}

const aiSlice = createSlice({
  initialState,
  name: 'ai',
  reducers: {
    setConversation: (state, action: PayloadAction<ConversationResponse>) => {
      state.conversationId = action.payload.id
      state.messages = [...action.payload.messages]
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setMessage: (state, action: PayloadAction<AiConversationMessage>) => {
      state.messages = [...state.messages, action.payload]
    },
    setOptions: (state, action: PayloadAction<AiSearchOptionsData[]>) => {
      const messagesLength = state.messages.length
      const lastMessage = state.messages[messagesLength - 1]

      const newOptionIds = action.payload
        .filter(option => state.linkedOptionIds.every(id => id !== option.id))
        .map(option => option.id)

      const exLastMessage = {
        ...lastMessage,
        optionsIds: [...newOptionIds],
      }
      const messages = [...state.messages]
      if (lastMessage.author === 'assistant') {
        messages[messagesLength - 1] = exLastMessage
      }

      state.options = [...action.payload]
      state.messages = [...messages]
      state.linkedOptionIds = [...state.linkedOptionIds, ...newOptionIds]
    },
  },
})

export const { setConversation, setLoading, setMessage, setOptions } = aiSlice.actions

export default aiSlice.reducer
