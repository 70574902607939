export const borderRadius = {
  md: '16px',
  mdNumber: 16,
  sml: '10px',
  smlNumber: 10,
  sm: '8px',
  smNumber: 8,
  xlg: '72px',
  xlgNumber: 72,
}
