import type { IUserState } from '@redux/features/user/types/userState'
import { commonAppEndpoints } from '@reduxCommonApp/commonApp.api'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import CookiesKeys from '@constants/cookies/cookiesKeys'
import { cookie } from '@services/cookies/cookies'

const initialState: IUserState = { currency: cookie.getCookie(CookiesKeys.USER_CURRENCY) }

const userSlice = createSlice({
  extraReducers: builder => {
    builder.addMatcher(commonAppEndpoints.getSiteInfo.matchFulfilled, (state, { payload }) => {
      state.currency = payload.currency
    })
  },
  initialState,
  name: 'user',
  reducers: {
    setUserCurrency: (state, action: PayloadAction<string>) => {
      state.currency = action.payload
    },
  },
})

export const { setUserCurrency } = userSlice.actions

export default userSlice.reducer
