import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { tildaPageUrl } from '@constants/api/api'
import getAppConfig from '@services/config/config'

const { publicRuntimeConfig } = getAppConfig()

const tildaApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: tildaPageUrl }),
  endpoints: build => ({
    getTildaPageFull: build.query<TidaPageFullType, number>({
      keepUnusedDataFor: 9999,
      query: pageId => ({
        params: {
          pageid: pageId,
          publickey: publicRuntimeConfig.tildaPublicKey,
          secretkey: publicRuntimeConfig.tildaSecretKey,
        },
        url: '/v1/getpagefull/',
      }),
    }),
  }),
  reducerPath: 'tildaApi',
})

export const {
  middleware: tildaApiMiddleware,
  reducer: tildaApiReducer,
  reducerPath: tildaApiReducerPath,
  useGetTildaPageFullQuery,
} = tildaApi
