import type { RootState } from '@redux/store'

import { DeviceType } from '@Types/common/device'

export const selectLanguage = (state: RootState) => state.commonApp.language
export const selectIsIsMobile = (state: RootState) => state.commonApp.device.type === DeviceType.MOBILE
export const selectIsIsTablet = (state: RootState) => state.commonApp.device.type === DeviceType.TABLET
export const selectIsRtl = (state: RootState) => state.commonApp.isRtl
export const selectTheme = (state: RootState) => state.commonApp?.theme
export const selectIsPageInteraction = (state: RootState) =>
  state.commonApp?.isPageInteraction === undefined ? true : state.commonApp.isPageInteraction
export const selectIsRehydrated = (state: RootState) => state._persist.rehydrated
