export const loginFormId = 'login-form'

export enum LoginFieldsName {
  Email = 'email',
  Password = 'pass',
}

export const loginFieldsPlaceHolders: Record<LoginFieldsName, string> = {
  [LoginFieldsName.Email]: 'authorization.fields.email.placeholder',
  [LoginFieldsName.Password]: 'authorization.fields.password.placeholder',
}

export type LoginFormType = {
  [LoginFieldsName.Email]: string
  [LoginFieldsName.Password]?: string
}
