import { useContext } from 'react'

import { useAppSelector } from '@redux/hooks'
import { selectIsIsMobile } from '@reduxCommonApp/commonApp.selectors'

import { DeviceType } from '@Types/common/device'
import isClient from '@components/common/isClient'
import ServerSidePropsContext from '@pages/next/App/context/serverSidePropsContext'

export default () => {
  const serverSideProps = useContext(ServerSidePropsContext)
  const mediaQueryIsMobile = useAppSelector(selectIsIsMobile)

  return isClient ? mediaQueryIsMobile : serverSideProps.deviceType === DeviceType.MOBILE
}
