import type { RangePickerProps } from 'antd/es/date-picker'
import Form from 'antd/es/form'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import type { FC } from 'react'
import { memo, useCallback, useLayoutEffect, useMemo } from 'react'

import { fixedPostSearchCacheKey, usePostSearchMutation } from '@redux/features/checkout/api'
import { setQueryParams } from '@redux/features/checkout/checkout.slice'
import { selectIsOpenLayovers } from '@redux/features/recommendedRoutes/recommendedRoutes.selectors'
import { useAppDispatch, useAppSelector } from '@redux/hooks'

import DateInput from '@components/mainPage/mainBlock/searchTrains/search/searchForm/DateInput'
import { popupPositionsMainPage } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/DateInput/constants/positions'
import { searchFormId } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/form'
import PassengersLabel from '@components/mainPage/mainBlock/searchTrains/search/searchForm/passengers/Passengers'
import StationsFields from '@components/mainPage/mainBlock/searchTrains/search/searchForm/stationsFields/StationsFields'
import SubmitButton from '@components/mainPage/mainBlock/searchTrains/search/searchForm/submitButton/SubmitButton'
import {
  fieldsHandlers,
  onErrorHandler,
} from '@components/mainPage/mainBlock/searchTrains/search/searchForm/utils/errorHandler'
import type { SearchFormValuesType } from '@components/mainPage/mainBlock/searchTrains/search/types/formTypes'
import ShadowMask from '@components/mask/ShadowMask'
import { mainHeaderId } from '@constants/elementsIds/header'
import routes from '@constants/routes/routes'
import useIsMobile from '@hooks/mediaQueries/useIsMobile'
import useIsTablet from '@hooks/mediaQueries/useIsTablet'
import useIsMainPage from '@hooks/useIsMainPage'
import useTrainSearchForm from '@hooks/useTrainSearch/useTrainSearchForm'

const RecommendedRoutes = dynamic(() => import('@components/mainPage/recommendedRoutes/RecommendedRoutes'))

const SearchForm: FC = () => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const {
    adults,
    arrivalStationValue,
    children,
    childrenAge,
    closePopup,
    date,
    departureStationValue,
    form,
    isCalendarOpened,
    isPassengersOpened,
    isRoundTrip,
    layoverValue,
    maxPassengersCount,
    onClearReturnDate,
    onSearchHandler,
    openCalendar,
    openedPopup,
    openPassengers,
  } = useTrainSearchForm({})
  const isTablet = useIsTablet()
  const isMobile = useIsMobile()
  const isOpenLayovers = useAppSelector(selectIsOpenLayovers)
  const isMainPage = useIsMainPage()

  const [, { isLoading }] = usePostSearchMutation({ fixedCacheKey: fixedPostSearchCacheKey })

  useLayoutEffect(() => {
    const header = document.getElementById(mainHeaderId)
    if (header) {
      header.style.zIndex = openedPopup ? '0' : '3'
    }
    /*if (main) {
      main.style.zIndex = openedPopup ? 'unset' : '1'
    }*/
  }, [openedPopup])

  const onFinish = useCallback(
    (values: SearchFormValuesType) => {
      onSearchHandler(values)
      document.location.search && dispatch(setQueryParams(document.location.search))
      void router.push(routes.checkoutTimetable)
    },
    [router, onSearchHandler]
  )

  const datePickerProps = useMemo<RangePickerProps>(
    () => ({
      disabled: [false, isOpenLayovers || !!layoverValue],
      popupStyle: popupPositionsMainPage(isTablet, !!layoverValue),
    }),
    [isOpenLayovers, layoverValue, isTablet]
  )

  return (
    <>
      <Form
        form={form}
        name={searchFormId}
        onFinish={onFinish}
        onFinishFailed={onErrorHandler(fieldsHandlers)}
        style={{ width: '100%', zIndex: 4 }}
        validateMessages={undefined}
      >
        <StationsFields
          arrivalStationValue={arrivalStationValue}
          departureStationValue={departureStationValue}
          disableClear={isMobile}
          isMainPage={isMainPage}
          layoverValue={layoverValue}
          setFieldValue={form.setFieldValue}
        />
        <DateInput
          close={closePopup}
          datePickerProps={datePickerProps}
          isOpened={isCalendarOpened}
          onClearReturnDate={onClearReturnDate}
          open={openCalendar}
          position="right"
        />
        <PassengersLabel
          adultsCount={adults || 1}
          childrenAge={childrenAge}
          childrenCount={children}
          isOpened={isPassengersOpened}
          maxPassengersCount={maxPassengersCount}
          onClose={closePopup}
          onOpen={openPassengers}
        />
        {(arrivalStationValue || departureStationValue) && (
          <RecommendedRoutes
            arrivalStation={arrivalStationValue}
            departureDate={!isRoundTrip && date ? date[0] : undefined}
            departureStation={departureStationValue}
            selectedLayover={layoverValue}
            setStation={form.setFieldValue}
            submitForm={form.submit}
          />
        )}
        <SubmitButton isUploading={isLoading} />
      </Form>
      {openedPopup && <ShadowMask />}
    </>
  )
}

export default memo(SearchForm)
